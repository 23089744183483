import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
// Pages
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import DashboardApp from "./pages/DashboardApp";
import { types } from "./controllers";
import EditorOnlySection from "./pages/EditorOnlySection";
import TableView from "./layouts/dashboard/TableView";
import CustomView from "./layouts/dashboard/CustomView";
import Gallery from "./pages/Gallery";
import Hero from "./pages/Hero";
import Menu from "./pages/Menu";
import Venue from "./pages/Venue";
import AboutUs from "./pages/AboutUs";
import FoodDrink from "./pages/FoodDrink";

const publicRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <LogoOnlyLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

const protectedRoutes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      {
        path: "/dashboard",
        element: <DashboardApp />,
      },
      { path: "/hero", element: <Hero /> },
      { path: "/events", element: <TableView type={types.EVENTS} /> },
      { path: "/gallery", element: <Gallery /> },
      {
        path: "/foodAndDrinks",
        element: <FoodDrink />,
      },
      { path: "/faq", element: <TableView type={types.FAQ} /> },
      {
        path: "/menu",
        element: <Menu />,
      },
      // {
      //   path: "/venue",
      //   element: <Venue />,
      // },
      {
        path: "/venueBooking",
        element: <TableView type={types.VENUE_BOOKING} />,
      },
      {
        path: "/aboutUs",
        element: <AboutUs />,
      },
      {
        path: "/contactUs",
        element: <TableView type={types.CONTACT_US} />,
      },
      {
        path: "/subscribers",
        element: <TableView type={types.SUBSCRIBER} />,
      },
      {
        path: "/termsAndCondition",
        element: (
          <EditorOnlySection
            type={types.CMS}
            cmsTitle="Terms and condition"
            title="Terms & Conditions"
          />
        ),
      },
      {
        path: "/privacyPolicy",
        element: (
          <EditorOnlySection
            type={types.CMS}
            cmsTitle="Privacy Policy"
            title="Privacy Policy"
          />
        ),
      },
    ],
  },
  { path: "/", element: <Navigate to="/dashboard" /> },
  { path: "404", element: <NotFound /> },
  { path: "*", element: <Navigate to="/404" /> },
];

export default function Router() {
  const { authToken } = useSelector((state) => state.reducer);
  if (authToken) return useRoutes(protectedRoutes);
  return useRoutes(publicRoutes);
}
