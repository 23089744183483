import Iconify from "../../components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "Hero Title",
    path: "/hero",
    icon: getIcon("bi:card-heading"),
  },
  {
    title: "Events",
    path: "/events",
    icon: getIcon("fluent-mdl2:product-catalog"),
  },
  {
    title: "Gallery",
    path: "/gallery",
    icon: getIcon("fa-regular:images"),
  },
  {
    title: "Food and Drinks",
    path: "/foodAndDrinks",
    icon: getIcon("map:food"),
  },
  {
    title: "Menu",
    path: "/menu",
    icon: getIcon("bxs:food-menu"),
  },
  // {
  //   title: "Venue",
  //   path: "/venue",
  //   icon: getIcon("ep:place"),
  // },
  {
    title: "Venue Booking",
    path: "/venueBooking",
    icon: getIcon("ep:place"),
  },
  {
    title: "FAQ",
    path: "/faq",
    icon: getIcon("mdi:faq"),
  },
  {
    title: "About Us",
    path: "/aboutUs",
    icon: getIcon("mdi:about-variant"),
  },
  {
    title: "Inquiries",
    path: "/contactUs",
    icon: getIcon("material-symbols:contact-page-outline-rounded"),
  },
  {
    title: "Subscribers",
    path: "/subscribers",
    icon: getIcon("material-symbols:contact-page-outline-rounded"),
  },
  {
    title: "Terms and conditions",
    path: "/termsAndCondition",
    icon: getIcon("ic:sharp-library-books"),
  },
  {
    title: "Privacy Policy",
    path: "/privacyPolicy",
    icon: getIcon("ic:sharp-privacy-tip"),
  },
];

export default navConfig;
